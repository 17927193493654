import styles from '../css/app.scss';

import $ from 'jquery';
window.jQuery = $;

// import 'popper.js';
// import Cookies from 'js-cookie';
// window.Cookies = Cookies;

import SimpleBar from 'simplebar';

import io from 'socket.io-client';

//import 'owl.carousel';
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;

//import 'bootstrap';
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/collapse';

import bgset from 'lazysizes/plugins/bgset/ls.bgset.min'; // Used for backgrounds.
import lazySizes from 'lazysizes';

window.fitWithin = function(objWidth, objHeight, targWidth, targHeight){
  var imgWidth, imgHeight;
	var theImgRatio = objWidth/objHeight;
	var theScrRatio = targWidth/targHeight;
	if (theImgRatio < theScrRatio) {
		imgHeight = targHeight;
		imgWidth = Math.round(theImgRatio * targHeight);
	} else {
		imgWidth = targWidth;
		imgHeight = Math.round(targWidth / theImgRatio);
	}
	var tmpArr = new Array(2);
	tmpArr['width'] = imgWidth;
	tmpArr['height'] = imgHeight;
	return tmpArr;
}

window.isHome = false;
window.winWd = 0;
window.winHt = 0;
window.showingLightbox = false;

window.$responsiveElem = {};
window.responsiveFontSize = 10;
window.$lightbox;
window.lightboxOpen = false;
window.lightboxOwlCarousel;

var colContainerWidth = 0;
var colActiveWidth = 383;
var numCols = 6;
var hasHoverClass = false;

window.winResize = function(){
  window.winWd = jQuery(window).width();
  window.winHt = jQuery(window).height();

  // to set the viewport height accurately...
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.responsiveFontSize = parseFloat(window.$responsiveElem.css('font-size'));

  if (window.responsiveFontSize < 12) {
    $('.column').css({ 'width': '' });
  } else {
    colContainerWidth = $('.columns-container').width();
    colActiveWidth = 383;
    if (window.responsiveFontSize > 12) {
      colActiveWidth = 540;
    }
  }
}


var setupHomeSockets = function(){
  var socket = io(process.env.NODEJS_CONNECTION_URL);
  socket.on('connect', function(){
    console.log('socket connected');
  });
  socket.on('message', function(data){
    console.log(data);
  });
  socket.on('msg-deleted', function(data){
    console.log('deleted');
    var jsonData = JSON.parse(data);
    var $msg = $('#message-'+jsonData.id);
    var $colSimplebar = $msg.closest('.column').data('simplebar');
    $msg.remove();
    $colSimplebar.recalculate();
    // if (data.message == '') {
    //
    // }
  });
  socket.on('msg-updated', function(data){
    console.log('updated');
    var jsonData = JSON.parse(data);
    var $msg = $('#message-'+jsonData.id);
    var $colSimplebar = $msg.closest('.column').data('simplebar');
    var html = $('<div>'+jsonData.html+'</div>').find('.message').html();
    $msg.html(html);
    $colSimplebar.recalculate();
    // if (data.message == '') {
    //
    // }
  });
  socket.on('msg-created', function(data){
    console.log('created');
    var jsonData = JSON.parse(data);
    var $col = $('.column:eq(0)');
    var $colContentEl = $col.data('content');
    $colContentEl.prepend(jsonData.html);
    var $colSimplebar = $col.data('simplebar');
    $colSimplebar.recalculate();
    // if (data.message == '') {
    //
    // }
  });
  socket.on('disconnect', function(){});
}


//jQuery(document).ready(function(){   // old jquery ready method...
window.setupStuff = function(){

  window.$lightbox = jQuery('#lightbox');

  jQuery('body').prepend('<div class="responsive-test"></div>');
  window.$responsiveElem = $('body > .responsive-test');

  window.isHome = jQuery('body').hasClass('home');
  jQuery(window).on('resize', function(){
    window.winResize();
  });
  window.winResize();
  setTimeout(window.winResize, 500);

  // -------------------------------------
  // hover
  // use "html.hasHover a:hover" class for exclusive mouse hover styling
  // use "a.thover" class for touch enabled devices to toggle hover styling
  // -------------------------------------

  var container = document.documentElement;
  var lastTouchTime = 0;

  if (!!('ontouchstart' in window)) {
    $('body').on('click', '.tToggle', function(){
      if (!hasHoverClass)
        $(this).toggleClass('touch-hover');
    });
  }

  function enableHover() {
      // filter emulated events coming from touch events
      if (new Date() - lastTouchTime < 500) return;
      if (hasHoverClass) return;

      container.className += ' hasHover';
      hasHoverClass = true;
  }

  function disableHover() {
      if (!hasHoverClass) return;

      container.className = container.className.replace(' hasHover', '');
      hasHoverClass = false;
  }

  function updateLastTouchTime() {
      lastTouchTime = new Date();
  }

  document.addEventListener('touchstart', updateLastTouchTime, true);
  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  enableHover();
  // -------------------------------------

  $('a[href^="#"].smooth-scroll').on('click', function(event) {
      var target = $(this.getAttribute('href'));
      if( target.length ) {
          event.preventDefault();
          $('html, body').stop().animate({
              scrollTop: target.offset().top
          }, 1000);
      }
  });

  numCols = $('.column').length;
  $('.column:last-child').addClass('last-child');

  var layoutCols = function(){
    if (window.responsiveFontSize > 11) {
      $('.column.active').css({ width: '' });
      if (hasHoverClass && $('.column.hover.active').length == 0 && $('.column.hover').length == 1) {

        var virtualCols = 5 * (numCols-1);
        var nonHoverWidth = ((colContainerWidth - colActiveWidth) / virtualCols) * 5;
        var hoverWidth = ((colContainerWidth - colActiveWidth) / virtualCols) * 6;

        $('.column:not(.active, .hover, .last-child)').css({ width: nonHoverWidth+'px' });
        $('.column.hover:not(.last-child)').css({ width: hoverWidth+'px' });

      } else {
        $('.column:not(.active, .last-child)').css({ width: ((colContainerWidth - colActiveWidth) / (numCols-1))+'px' });
      }
    }
  }
  $(window).on('resize', function(){
    layoutCols();
  });
  layoutCols();

  if (hasHoverClass) {
    $('.column').on('mouseenter', function(event){
      $('.columns-container').addClass('fast-transition');
      $(this).addClass('hover');
      layoutCols();
    }).on('mouseleave', function(event){
      $('.columns-container').removeClass('fast-transition');
      $(this).removeClass('hover');
      layoutCols();
    });
  }

  $('.column').on('click', function(event){
    $('.columns-container').removeClass('fast-transition');
    $('.column').removeClass('active');
    $(this).addClass('active');
    layoutCols();
  });
  $('.column .toggler').on('click', function(event){
    console.log('clicked: '+$(this).parent().attr('id'));
    $(this).parent().removeClass('active');
    event.stopPropagation();
  });

  $('.column').each(function(index){
    var $col = $(this);
    var colSimpleBar = new SimpleBar($(this).find('.scrolling')[0], {
      autoHide: false,
      scrollbarMinSize: 8
    });
    $col.data('simplebar', colSimpleBar);
    var $scrollContent = $(colSimpleBar.getContentElement());
    var $scrollElement = $(colSimpleBar.getScrollElement());
    $col.data('content', $scrollContent);

    var loadingMore = false;
    var $loadMore = $col.find('.load-more');

    var loadMore = function(){
      if (loadingMore) return false;
      loadingMore = true;
      $col.addClass('loading');
      var url = $loadMore.attr('href');
      var offset = $col.find('.message').length;
      url = url + "?offset=" + offset;
      $.ajax({url: url, success: function(result){
        $col.removeClass('loading');
        var $scrollContent = $(colSimpleBar.getContentElement());
        $loadMore.remove();
        $scrollContent.find('.scrolling-inner').append(result);
        colSimpleBar.recalculate();
        $loadMore = $col.find('.load-more');
        loadingMore = false;
      }});
    }

    var scrollContentHt = 0;
    var scrollElementHt = 0;
    var gotSizes = false;

    var getSizes = function(){
      if (!gotSizes) {
        scrollElementHt = $scrollElement.height();
        scrollContentHt = $scrollContent.height();
        gotSizes = true;
      }
    }

    jQuery(window).on('resize', function(){
      gotSizes = false;
      getSizes();
    });

    $scrollElement.on('scroll', function(event){
      //console.log("scrollTop: "+$(this).scrollTop()+" / $scrollElement.height(): "+$scrollElement.height() + " / $scrollContent.height(): "+$scrollContent.height());
      getSizes();
      if (!loadingMore && $loadMore.length > 0 && ($(this).scrollTop() + scrollElementHt > (scrollContentHt - 5))) {
        //console.log('bottom');
        loadMore();
      }
    });

    $(this).on('click', '.load-more', function(event){
      event.preventDefault();
      loadMore();
      return false;
    });
  });



  var headerSimplebar = new SimpleBar($('.header-info .scrolling')[0], {
    autoHide: false,
    scrollbarMinSize: 8
  });
  var resetHeaderInfo = function(){
    $('.header-info .scrolling-inner').css({ 'marginTop': '' });
  }
  var checkHeaderInfo = function(){
    var scrollInnerHt = $('.header-info .scrolling-inner').height();
    var scrollHt = $('.header-info .scrolling').height();
    if (scrollInnerHt < scrollHt) {
      $('.header-info .scrolling-inner').css({ 'marginTop': ((scrollHt - scrollInnerHt)/2)+"px" });
    }
    headerSimplebar.recalculate();
  }
  checkHeaderInfo();
  $(window).on('resize', function(){
    resetHeaderInfo();
    checkHeaderInfo();
  });

  $(window).click(function() {
    $('body').addClass('header-compact');
  });
  $('header').click(function(event){
    event.stopPropagation();
  });

  jQuery('header .navbar-toggler').on('click', function(){
    resetHeaderInfo();
    jQuery('body').toggleClass("header-compact");
    $('header .header-nav li').removeClass('active');
    $('header .header-info section').removeClass('active');
    $('header .header-info #section_base').addClass('active');
    checkHeaderInfo();
  });

  $('header .header-nav a').on('click', function(event){
    resetHeaderInfo();
    event.preventDefault();
    var sectionId = $(this).attr('href').split('#')[1];
    $('header .header-nav li, .header-info section').removeClass('active');
    $('header .header-nav li.'+sectionId+", .header-info section#section_"+sectionId).addClass('active');
    checkHeaderInfo();
  });



  $('body').append('<div id="lightbox" class="hidden">\
    <div class="content"></div>\
    <div class="toggler close-lightbox">\
      <span class="icon"></span>\
    </div>\
  </div>');
  window.$lightbox = $('#lightbox');

  var closeLightbox = function(){
    window.$lightbox.addClass('hidden');
    window.lightboxOpen = false;
  }

  $(document).bind('keydown', function(e) {
    if (window.lightboxOpen) {
      if (e.key == "Escape") {
        closeLightbox();
      }
      if (e.key == "ArrowRight") {
        window.lightboxOwlCarousel.trigger('next.owl.carousel');
      }
      if (e.key == "ArrowLeft") {
        window.lightboxOwlCarousel.trigger('prev.owl.carousel');
      }
    }
  });

  $('.col-header .image-wrap').on('click', function(){
    var $parent = $(this).parent();
    var carouselHtml = $parent.find('.carousel-content').val();
    window.$lightbox.find('.content').html(carouselHtml);
    window.$lightbox.removeClass('hidden');
    window.lightboxOwlCarousel = window.$lightbox.find('.owl-carousel').owlCarousel({
			loop:false,
			autoplay: false,
			smartSpeed: 500,
			dots: true,
			items:1
		});
    $('.owl-item').click(function() {
      window.lightboxOwlCarousel.trigger('next.owl.carousel');
    });
    window.lightboxOpen = true;
  });
  window.$lightbox.find('.close-lightbox').on('click', function(){
    //window.$lightbox.find('.content').html('');
    closeLightbox();
  });


  // if (window.isHome) {
  //   setupHomeSockets();
  // }

}
window.setupStuff();

jQuery(document).ready(function(){
  window.winResize();
});
